<template>
	<section>
		<div
			class="
        col-sm col-md
        justify-content-center
        align-items-center
        d-lg-flex
        px-0
      "
			>
			<div class="col pl-0">
				<div
					v-if="loading"
					class="card mb-3 d-flex justify-content-center align-items-center p-5"
					style="max-width: 25rem"
					>
					<div class="spinner-border" role="status"></div>
				</div>
				<div v-else class="card mb-3" style="max-width: 25rem">
					<div class="card-header bg-transparent font-weight-bolder">
						Total Revenue
					</div>

					<div
						class="
              card-body
              d-flex
              justify-content-center 
              align-items-center
              flex-column
              p-5
            "
						>
						<h1 class="card-title display-4">{{ totalRevenue }}</h1>
						<small class="card-text text-muted text-sm">TOTAL EARNINGS</small>
					</div>
				</div>
			</div>

			<div class="col">
				<div
					v-if="loading"
					class="card mb-3 d-flex justify-content-center align-items-center p-5"
					style="max-width: 25rem"
					>
					<div class="spinner-border" role="status"></div>
				</div>
				<div v-else class="card mb-3" style="max-width: 25rem">
					<div class="card-header bg-transparent font-weight-bolder">
						Shuttlers Revenue
					</div>
					<div
						class="
              card-body
              d-flex
              justify-content-center
              align-items-center
              flex-column
              p-5
            "
						>
						<h1 class="card-title display-4">{{ shuttlersRevenue }}</h1>
						<small class="card-text text-muted text-sm"
							>SHUTTLERS EARNINGS</small
							>
					</div>
				</div>
			</div>

			<div class="col pr-0">
				<div
					v-if="loading"
					class="card mb-3 d-flex justify-content-center align-items-center p-5"
					style="max-width: 25rem"
					>
					<div class="spinner-border" role="status"></div>
				</div>
				<div v-else class="card mb-3" style="max-width: 25rem">
					<div class="card-header bg-transparent">
						<div class="font-weight-bold font-weight-bolder">
							Partner Revenue
						</div>
						<div>
							<b-button class="btn btn-danger" v-b-modal.deductionForm
								>Deduct</b-button
								>
						</div>
					</div>
					<div
						class="
              card-body
              d-flex
              justify-content-center
              align-items-center
              flex-column
              p-5
            "
						>
						<h1 class="card-title display-4">{{ partnersRevenue }}</h1>
						<small class="card-text text-muted text-sm">PARTNER EARNINGS</small>
					</div>
				</div>
			</div>
		</div>

		<div class="card w-100 mt-5">
			<div class="card-header bg-transparent">
				<div class="font-weight-bold">Deduction History</div>
				<download-button
					v-if="deductionHistory"
					:downloading="downloadingReport"
					label="Download Report"
					@download="downloadReport()"
					></download-button>
			</div>
			<b-table
				striped
				hover
				selectable
				responsive
				show-empty
				:items="deductionHistory"
				:fields="deductionHistoryFields"
				:busy="loading"
				>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<strong>Loading...</strong>
					</div>
				</template>
				<template #empty>
					<h4 class="text-center text-secondary">No records available</h4>
				</template>
				<template #cell(current_date)="data">
					{{ data.item.createdAt | date('MMMM do, yyyy') }}
				</template>
				<template #cell(route_code)>
					{{ route_code }}
				</template>
				<template #cell(partners_revenue)>
					&#8358;{{ partners_revenue | money }}
				</template>
				<template
					#cell(deducted_amount)="data"
					:class="[data.item.amount > 10000 ? 'text-danger' : 'text-dark']"
					>
					{{ handleNumberToTextConversion(data.item.amount) }}
				</template>
				<template #cell(description)="data">
					{{ data.item.description }}
				</template>
			</b-table>

			<div class="card-footer" v-if="totalRecords">
				<div class="row align-items-center">
					<div class="col">
						<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
					</div>
					<div class="col-auto">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRecords"
							:per-page="pageSize"
							></b-pagination>
					</div>
				</div>
			</div>
		</div>

		<div>
			<b-modal
				no-close-on-esc
				no-close-on-backdrop
				id="deductionForm"
				title="Deduct From Revenue"
				no-stacking
				>
				<p>You are about to deduct from a Partners account</p>
				<form class="pt-4">
					<div class="form-group">
						<label class="form-label text-muted">Amount</label>
						<input
							v-model.trim="form.amount"
							required
							type="number"
							placeholder="N"
							class="form-control"
							/>
					</div>
					<div class="form-group">
						<label for="topup-description" class="col-form-label text-muted"
							>Description</label
							>
						<textarea
							:class="[
								form.description.length > totalDescriptionLength
									? 'border border-danger'
									: '',
							]"
							v-model.trim="form.description"
							class="form-control"
							id="topup-description"
							required
							></textarea>
						<div class="d-flex justify-content-end align-items-end">
							<small class="text-muted mt-2"
								>{{ form.description.length }} of
								{{ totalDescriptionLength }}</small
								>
						</div>
					</div>
				</form>
				<template #modal-footer>
					<b-button @click="close" variant="secondary">Close</b-button>
					<b-button
						@click="proceed"
						:disabled="!form.amount.length && !form.description.length"
						:class="[
							form.amount.length && form.description.length
								? 'bg-primary'
								: 'bg-secondary',
						]"
						>Proceed</b-button
						>
				</template>
			</b-modal>

			<b-modal
				no-close-on-esc
				no-close-on-backdrop
				id="confirmDeduction"
				title="Deduct Earning"
				>
				<p class="font-weight-light">Are you absolutely sure?</p>
				<div
					class="
            alertDisplay
            rounded
            p-3
            d-flex
            align-items-center
            justify-content-center
          "
					role="alert"
					>
					<div>
						<img
							src="@/assets/img/caution.svg"
							class="h-3 w-3 pr-2"
							alt="cauton"
							/>
					</div>
					<div>
						<span class="alertText pl-2"
							>It’s important you read the message below!</span
							>
					</div>
				</div>
				<p class="font-weight-light py-3">
					Deducting from a vehicle partner’s account will affect their earnings
					for this particular trip.
				</p>
				<form>
					<div class="form-group">
						<label for="exampleInputOption" class="text-secondary"
							>Please type
							<span class="font-weight-bold text-dark">YES</span> to confirm
							this action.</label
							>
						<input
							v-model="confirmation"
							type="text"
							class="form-control"
							id="exampleInputOption"
							aria-describedby="option"
							/>
					</div>
				</form>
				<template #modal-footer>
					<b-button @click="hide">Cancel</b-button>
					<b-button
						@click="proceedingDeduction"
						:class="[confirmation.length ? 'bg-dark' : 'bg-secondary']"
						>
						{{ modalLoadingState ? 'Processing..' : 'Deduct' }}</b-button
						>
				</template>
			</b-modal>
		</div>
	</section>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import {
  extractErrorMessage,
  formatNumberAsCurrencyText,
  monitizeValue
} from '@/utils/helpers'
import DownloadButton from '@/components/core/DownloadButton'
import moment from 'moment'
import TableView from '@/mixins/tableview'

export default {
  name: 'TripFinancials',
  components: { DownloadButton },
  mixins: [TableView],
  data () {
    return {
      loading: false,
      deductionHistoryFields: [
        {
          key: 'current_date',
          label: 'Date'
        },
        {
          key: 'route_code',
          label: 'Route code'
        },
        {
          key: 'partners_revenue',
          label: 'Partner revenue'
        },
        {
          key: 'deducted_amount',
          label: 'Deducted amount'
        },
        {
          key: 'description',
          label: 'Description'
        }
      ],
      deductionHistory: [],
      shuttlers_revenue: '',
      partners_revenue: '',
      total_revenue: '',
      proceedToDeduct: true,
      confirmDeduct: false,
      route_code: '',
      downloadingReport: false,
      form: {
        amount: '',
        description: ''
      },
      confirmation: '',
      totalDescriptionLength: 160,

      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      tripRecordId: '',
      modalLoadingState: false
    }
  },
  created () {
    this.getFinancials()
  },
  computed: {
    tablePageText () {
      // TODO: use tableView mixin when merged
      const cpSize = this.pageSize * this.currentPage
      if (this.currentPage === 1 && this.totalRecords <= this.pageSize) {
        return `Showing ${this.totalRecords} record${
          this.totalRecords > 1 ? 's' : ''
        }`
      } else {
        return this.totalRecords
          ? `Showing ${cpSize - this.pageSize + 1} - ${Math.min(
              cpSize,
              this.totalRecords
            )} of ${this.totalRecords}`
          : ''
      }
    },

    shuttlersRevenue () {
      return monitizeValue(this.shuttlers_revenue)
    },
    partnersRevenue () {
      return monitizeValue(this.partners_revenue)
    },
    totalRevenue () {
      return monitizeValue(this.total_revenue)
    }
  },
  methods: {
    handleNumberToTextConversion (number) {
      return formatNumberAsCurrencyText(number)
    },
    resetModalState () {
      return (
        (this.form.description = ''),
        (this.form.amount = ''),
        (this.confirmation = '')
      )
    },
    hide () {
      this.$bvModal.hide('confirmDeduction')
      this.$bvModal.show('deductionForm')
      this.confirmation = ''
    },
    close () {
      this.$bvModal.hide('deductionForm')
      this.form.amount = ''
      this.form.description = ''
    },
    proceed () {
      if (this.form.amount === '' || this.form.description === '') {
        return
      }
      this.$bvModal.show('confirmDeduction')
    },

    downloadReport () {
      this.downloadingReport = true
      const csvData = this.deductionHistory.map((data) => {
        return {
          deductedAmount: data.amount,
          description: data.description,
          routeCode: this.route_code,
          earnings: this.partners_revenue,
          date: moment(data.createdAt).format('Do, MMMM YYYY')
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: 'Deduction History',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Deduction History',
        useTextFile: false,
        useBom: true,
        headers: [
          'Deducted Amount',
          'Description',
          'Route Code',
          'Partners Revenue',
          'Date'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },

    async getFinancials () {
      this.loading = true
      try {
        const url = `/cost-revenue/v1/trips/${this.$route.params.tripId}/revenues`
        const response = await this.axios.get(url)
        response ? response.data : {}
        const {
          deductions,
          finalPartnersRevenue,
          partnersRevenue,
          shuttlersRevenue,
          metadata,
          id
        } = response.data
        const partners_revenue = finalPartnersRevenue
        this.deductionHistory = deductions
        this.shuttlers_revenue = shuttlersRevenue
        this.partners_revenue = partners_revenue
        this.total_revenue = partnersRevenue
        this.route_code = metadata?.routeCode
        this.totalRecords = this.pageSize = deductions?.length
        this.tripRecordId = id
      } catch (error) {
        const msg = extractErrorMessage(
          error,
          'An error occurred, please try again.'
        )
        this.$swal().showValidationMessage(msg)
      } finally {
        this.loading = false
      }
    },
    async proceedingDeduction () {
      if (this.confirmation === 'YES') {
        const deductionData = {
          amount: Number(this.form.amount),
          description: this.form.description
        }
        this.modalLoadingState = true
        try {
          const res = await this.axios.post(
            `/cost-revenue/v1/revenues/${this.tripRecordId}/deductions`,
            deductionData
          )
          this.$bvModal.hide('confirmDeduction')
          this.$bvModal.hide('deductionForm')
          this.$toastr.s(res.data.message, 'Success')
          this.resetModalState()
          await this.getFinancials()
        } catch (error) {
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
        } finally {
          this.modalLoadingState = false
        }
      } else {

      }
    }
  }
}
</script>

<style scoped>
.alertDisplay {
  background-color: #ebebff;
}

.alertText {
  color: rgb(37, 3, 61);
}
</style>
